import React from "react";
import ContractDropdown from "containers/ContractDropdown";
import Breadcrumbs from "containers/Breadcrumbs";

import styles from "./styles.module.css";

export interface PageHeaderProps {
  title: React.ReactNode;
}

const PageHeader: React.FC<PageHeaderProps> = ({ title }) => {
  return (
    <header className={styles.root}>
      <h1 className={styles.title}>{title}</h1>
      <div className={styles.subtitleBlock}>
        <ContractDropdown className={styles.contractDropdown} />
        <Breadcrumbs />
      </div>
    </header>
  );
};

export default PageHeader;

export interface BreadcrumbsItem {
  label: string;
  path: string;
  dustinIcon?: string;
}

export interface BreadcrumbsConfigItem {
  label: string;
  crumbs?: BreadcrumbsItem[];
  dustinIcon?: string;
  match: { path: string; exact?: boolean };
}

const ModernWorkplace: BreadcrumbsItem = {
  label: "Modern Workplace",
  path: "/mw",
  dustinIcon: "laptop",
};

const breadcrumbsConfig: BreadcrumbsConfigItem[] = [
  {
    label: ModernWorkplace.label,
    dustinIcon: ModernWorkplace.dustinIcon,
    match: {
      path: ModernWorkplace.path,
      exact: true,
    },
  },
  {
    label: "Devices",
    crumbs: [ModernWorkplace],
    dustinIcon: "computer",
    match: {
      path: "/mw/devices",
    },
  },
  {
    label: "Users",
    crumbs: [ModernWorkplace],
    dustinIcon: "user",
    match: {
      path: "/mw/users",
    },
  },
  {
    label: "Licenses",
    crumbs: [ModernWorkplace],
    dustinIcon: "document-check",
    match: {
      path: "/mw/licenses",
    },
  },
  {
    label: "Applications",
    crumbs: [ModernWorkplace],
    dustinIcon: "doc",
    match: {
      path: "/mw/apps",
    },
  },
];

export default breadcrumbsConfig;

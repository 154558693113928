// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_filterBar__8C4Dc {\n  width: 20%;\n  min-width: 200px;\n  margin-right: 16px;\n  margin-bottom: 16px;\n}\n\n.styles_filterLabel__-eZae {\n  margin-bottom: 4px;\n  color: #4f4f4f;\n  font-size: 12px;\n}\n\n.styles_autocomplete__-9fuG {\n  min-height: 37px !important;\n  padding-top: 2px;\n}\n\n@media (max-width: 480px) {\n  .styles_filterBar__8C4Dc {\n    width: 100%;\n    margin-right: 0;\n    margin-left: 0;\n  }\n}\n", "",{"version":3,"sources":["webpack://./src/containers/AutocompleteTableFilter/styles.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,2BAA2B;EAC3B,gBAAgB;AAClB;;AAEA;EACE;IACE,WAAW;IACX,eAAe;IACf,cAAc;EAChB;AACF","sourcesContent":[".filterBar {\n  width: 20%;\n  min-width: 200px;\n  margin-right: 16px;\n  margin-bottom: 16px;\n}\n\n.filterLabel {\n  margin-bottom: 4px;\n  color: #4f4f4f;\n  font-size: 12px;\n}\n\n.autocomplete {\n  min-height: 37px !important;\n  padding-top: 2px;\n}\n\n@media (max-width: 480px) {\n  .filterBar {\n    width: 100%;\n    margin-right: 0;\n    margin-left: 0;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterBar": "styles_filterBar__8C4Dc",
	"filterLabel": "styles_filterLabel__-eZae",
	"autocomplete": "styles_autocomplete__-9fuG"
};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__HwqIO {\n  display: flex;\n  gap: 8px;\n}\n\n@media (max-width: 767px) {\n  .styles_root__HwqIO {\n    padding: 8px;\n    border-radius: 4px;\n    margin: 8px 0;\n    background-color: #d2d6de;\n    font-size: 14px;\n  }\n}\n\n.styles_breadcrumbs__i0A1u {\n  display: flex;\n  align-items: center;\n  color: black;\n  font-size: 12px;\n  text-transform: capitalize;\n}\n\n.styles_breadcrumbLink__cdONu {\n  display: flex;\n  align-items: center;\n  color: black;\n  text-decoration: none;\n}\n\n.styles_base64LinkIcon__a\\+Z3X {\n  width: 12px;\n  height: 12px;\n  margin-right: 4px;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/Breadcrumbs/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,QAAQ;AACV;;AAEA;EACE;IACE,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,eAAe;EACjB;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,0BAA0B;AAC5B;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".root {\n  display: flex;\n  gap: 8px;\n}\n\n@media (max-width: 767px) {\n  .root {\n    padding: 8px;\n    border-radius: 4px;\n    margin: 8px 0;\n    background-color: #d2d6de;\n    font-size: 14px;\n  }\n}\n\n.breadcrumbs {\n  display: flex;\n  align-items: center;\n  color: black;\n  font-size: 12px;\n  text-transform: capitalize;\n}\n\n.breadcrumbLink {\n  display: flex;\n  align-items: center;\n  color: black;\n  text-decoration: none;\n}\n\n.base64LinkIcon {\n  width: 12px;\n  height: 12px;\n  margin-right: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__HwqIO",
	"breadcrumbs": "styles_breadcrumbs__i0A1u",
	"breadcrumbLink": "styles_breadcrumbLink__cdONu",
	"base64LinkIcon": "styles_base64LinkIcon__a+Z3X"
};
export default ___CSS_LOADER_EXPORT___;

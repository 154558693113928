// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_root__43WDg {\n  display: flex;\n  align-items: center;\n}\n\n.styles_container__HTYp3 {\n  width: 60%;\n  min-width: 70px;\n  height: 8px;\n  margin-right: 8px;\n  border-radius: 4px;\n}\n\n.styles_completed__f\\+Bim {\n  height: 100%;\n  border-right: 2px solid white;\n  border-bottom-left-radius: inherit;\n  border-top-left-radius: inherit;\n}\n\n.styles_emptyOrFullCompleted__E\\+DIf {\n  border-right: none;\n  border-bottom-right-radius: inherit;\n  border-top-right-radius: inherit;\n}\n", "",{"version":3,"sources":["webpack://./src/containers/ProgressBar/styles.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,UAAU;EACV,eAAe;EACf,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,kCAAkC;EAClC,+BAA+B;AACjC;;AAEA;EACE,kBAAkB;EAClB,mCAAmC;EACnC,gCAAgC;AAClC","sourcesContent":[".root {\n  display: flex;\n  align-items: center;\n}\n\n.container {\n  width: 60%;\n  min-width: 70px;\n  height: 8px;\n  margin-right: 8px;\n  border-radius: 4px;\n}\n\n.completed {\n  height: 100%;\n  border-right: 2px solid white;\n  border-bottom-left-radius: inherit;\n  border-top-left-radius: inherit;\n}\n\n.emptyOrFullCompleted {\n  border-right: none;\n  border-bottom-right-radius: inherit;\n  border-top-right-radius: inherit;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "styles_root__43WDg",
	"container": "styles_container__HTYp3",
	"completed": "styles_completed__f+Bim",
	"emptyOrFullCompleted": "styles_emptyOrFullCompleted__E+DIf"
};
export default ___CSS_LOADER_EXPORT___;
